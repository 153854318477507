<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>회사관리 {{ isNew ? '등록' : '수정' }}</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>회사정보</h2>
      </div>
      <ul class="filter_list">
        <li v-if="!isNew">
          <dd><label>회사코드</label>
            <ul>
              <li>{{cmpyInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>회사구분<span class="forg"> *</span></label>
            <ul>
              <li>
                <kendo-dropdownlist
                    ref="cmpyGbnRef"
                    style="width: 100%"
                    v-model="cmpyInfo.cmpyGbn"
                    :data-source="$store.state.codeRequestStore.search.combobox.cmpyGbn.data"
                    :data-text-field="'cdNm'"
                    :data-value-field="'cdId'"
                    :option-label="'선택'">
                </kendo-dropdownlist>
              </li>
            </ul>
          </dd>
          <dd><label>사업자등록번호<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <input
                    ref="corporateRegNoRef"
                    class="k-textbox"
                    style="width: 100%"
                    v-model.trim="cmpyInfo.corporateRegNo"
                    :maxlength="10"
                />
              </li>
              <li v-else>
                {{cmpyInfo.corporateRegNo}}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>회사명<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="cmpyNmRef"
                    class="k-textbox w75"
                    v-model.trim="cmpyInfo.cmpyNm"
                    @change="()=>{
                      this.isCheckCmpyNm = false
                      this.isPassCmpyNm = false
                    }"
                />
                <button
                    class="mid_btn orange ml5"
                    :disabled="isDisabledCheck || isPassCmpyNm"
                    @click="onClickCheckCmpyNm">중복체크
                </button>
              </li>
            </ul>
          </dd>
          <dd><label>대표자명</label>
            <ul>
              <li>
                <input
                    ref="ceoNmRef"
                    class="k-textbox w100"
                    v-model.trim="cmpyInfo.ceoNm"
                />
            </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>사업자 등록증</label>
            <ul>
              <li>
                <common-file-upload
                  ref="corporateFileIdRef"
                  :url="`${ApiConfig.efsDomain}/efs/file/upload`"
                  :download-domain="ApiConfig.efsDomain"
                  :old-file-id="cmpyInfo.corporateFileId"
                  v-model="cmpyInfo.corporateFile"
                  :multiple="false"
                  :maxfile="1"
                  :allowed-extensions="['.jpg','.png']"
                  :layout="'PREVIEW'"
                >
                </common-file-upload>
              </li>
            </ul>
          </dd>
          <dd><label>대표이메일</label>
            <ul>
              <li>
                <input
                    ref="cmpyMainMailRef"
                    type="email"
                    class="k-textbox w100"
                    v-model.trim="cmpyInfo.cmpyMainMail"
                />
              </li>
            </ul>
          </dd>
        </li>
      
        <li>
          <dd>
          <label>주소<span class="forg"> *</span></label>
            <ul class="w80">
              <li>
                <input
                    ref="zipNoRef"
                    class="k-textbox mr5"
                    style="width: 18%"
                    placeholder="우편번호"
                    v-model.trim="cmpyInfo.zipNo"
                    @focus="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()"
                />
                <input
                    ref="addrRef"
                    class="k-textbox w60"
                    placeholder="주소"
                    v-model.trim="cmpyInfo.addr"
                    @focus="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()"
                />
                <button
                    class="mid_btn orange ml5"
                    @click="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()">주소검색
                </button>
              </li>
            </ul>
          </dd>
          <dd><label>상세주소<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="addrDtlRef"
                    class="k-textbox w100"
                    placeholder="상세주소"
                    v-model.trim="cmpyInfo.addrDtl"
                />
              </li>
            </ul>
          </dd>
          
        </li>
        <li>
          <dd><label>식별 문자<span v-if="cmpyInfo.cmpyGbn === constant.cmpyGbn.client" class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="cmpyUnqTextRef"
                    class="k-textbox "
                    style="width: 100%"
                    @input="() => {cmpyInfo.cmpyUnqText = cmpyInfo.cmpyUnqText.toUpperCase().replace(/[^0-9A-Z]/g, '')}"
                    v-model.trim="cmpyInfo.cmpyUnqText"
                    maxlength="4"
                />
              </li>
            </ul>
          </dd>
        </li>
        <li v-if="isNew">
          <dd><label>로케이션주소<span class="forg"> *</span></label>
            <ul class="w80">
              <li>
                <kendo-dropdownlist
                    ref="defaultLocaInfoRef"
                    class="w20 mr5"
                    v-model="cmpyInfo.defaultLocaInfo"
                    :data-source="$store.state.codeRequestStore.search.combobox.defaultLocaInfo.data"
                    :data-text-field="'cdNm'"
                    :data-value-field="'cdId'"
                    :option-label="'선택'"
                    @select="setLocaAddr"
                >
                </kendo-dropdownlist>
               <input
                    ref="zipNoRef"
                    class="k-textbox mr5"
                    style="width: 18%"
                    disabled="disabled"
                    placeholder="우편번호"
                    v-model.trim="cmpyInfo.locaZipNo"
                />
                <input
                    ref="addrRef"
                    class="k-textbox w60"
                    disabled="disabled"
                    placeholder="주소"
                    v-model.trim="cmpyInfo.locaFullAddr"
                />
              </li>
            </ul>
          </dd>
          
        </li>
      </ul>
    </div>
    
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>추가 접속 가능 관리자
          <button class="mid_btn orange" @click="$refs.setSearchWindowRef.kendoWidget().center().open()">관리자검색</button>
        </h2>
      </div>
      <kendo-grid
          ref="companyMngGridRef"
          style="cursor: pointer"
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :data-source="cmpyMngList"
          :columns="gridColumns"
          :selectable="'row'"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
      </kendo-grid>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickCompanyInfoSave">저장</button>
        </li>
      </ul>
    </div>

    <kendo-window
        ref="setSearchWindowRef"
        :title="'관리자검색'"
        :modal="true"
        :width="'700px'"
        style="display:none; min-height: 600px;"
        @onPushUser="updateUserList"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <component :is="isWindowOpen ? 'SearchUser' : null"
                 :selectable="'multiple row'"
                 :not-search-cmpy-cd="cmpyInfo.cmpyCd"
                 :exist-key='cmpyMngList'>
      </component>
    </kendo-window>

    <!-- 주소검색 window -->
    <daum-postcode-window ref="postcodeWindowRef"
                          @select-addr="data=>{
                            cmpyInfo.zipNo = data.zipCode
                            cmpyInfo.addr = data.addr
                          }"
    ></daum-postcode-window>
  </div>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import SearchUser from '/src/components/SearchUser'

export default {
  name      : 'CompanyManageSave',
  components: {
    SearchUser,
    'DaumPostcodeWindow': () => import('/src/components/DaumPostcodeWindow.vue'),
  },
  created () {
    if (this.$route.params.cmpyCd) {
      this.isNew = false
      this.getCompanyManageDetail()
    }
  },
  computed : {
    isDisabledCheck : function (){
      const vm = this
      if(vm.cmpyInfo.cmpyNm === vm.bfCmpyNm){
        return true
      }
      if(vm.isNew){
        return !vm.cmpyInfo.cmpyNm;
      }else {
        return vm.cmpyInfo.cmpyNm === vm.initCmpyNm ;
      }
    }
  },
  watch : {
    cmpyMngList : function(newValue, oldValue){
      EtnersCommonUtil.setRowNumber(newValue)
    }
  },
  mounted   : function () {
  },
  methods   : {
    setLocaAddr : function (e){
      const vm = this
      let locaIAddrInfo = e.dataItem
      vm.cmpyInfo.locaNm = locaIAddrInfo.cdNm
      vm.cmpyInfo.locaZipNo = locaIAddrInfo.cdDesc1
      vm.cmpyInfo.locaAddr = locaIAddrInfo.cdDesc2
      vm.cmpyInfo.locaAddrDtl = locaIAddrInfo.cdDesc3
      vm.cmpyInfo.locaFullAddr = `${locaIAddrInfo.cdDesc2 ?? ''} ${locaIAddrInfo.cdDesc3 ?? ''}`
    },
    onClickCheckCmpyNm : function (){
      const vm = this
      if(!!vm.cmpyInfo.cmpyNm) vm.checkCmpyNm()
      else {
        kendo.alert("회사명을 입력해주세요.").bind('close', function() {
          vm.$refs.cmpyNmRef.focus()
        })
      }
    },
    checkCmpyNm : function (){
      const vm = this
      let param = {
        cmpyNm : vm.cmpyInfo.cmpyNm,
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/company/check`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        let isPass = response.data.resultData
        if(isPass){
          kendo.alert('등록가능한 회사명입니다.')
          vm.isPassCmpyNm = isPass
          vm.isCheckCmpyNm = isPass
          vm.bfCmpyNm = vm.$refs.cmpyNmRef.value
        }else{
          kendo.alert('중복된 회사명입니다.')
          vm.isPassCmpyNm = !isPass
          vm.isCheckCmpyNm = !isPass
        }
      })
    },
    onClickBrcUseYn : function (data){
      const vm = this
      if(data.value === 'Y'){
        vm.cmpyInfo.brcAutoYn = 'N'
      }else {
        vm.cmpyInfo.brcAutoYn = null
      }
    },
    convertGetSetCdArray(array){
      let convertSetCd = []
      array.forEach((item, i)=>{
        convertSetCd.push(item.setCd)
      })
      return convertSetCd
    },
    updateUserList : function (newUserData){
      const vm = this
      // 새로운데이터 추가
      newUserData.forEach((item, i)=>{
        let sameItem = vm.cmpyMngList.some(sameItem=> sameItem.unqUserId === item.unqUserId)
        if(!sameItem){
          vm.cmpyMngList.push({
            cmpyCd : item.cmpyCd,
            cmpyNm : item.cmpyNm,
            userNm : item.userNm,
            userId : item.userId,
            unqUserId : item.unqUserId,
            spTelNo : item.spTelNo
          })
        }
      })
      // 팝업에서 삭제한 데이터 목록에서 삭제
      vm.cmpyMngList.forEach((item, i)=>{
        let sameItem = newUserData.some(sameItem=> sameItem.unqUserId === item.unqUserId)
        if(!sameItem){
          vm.cmpyMngList.splice(i,1)
        }
      })
    },
    getCompanyManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/company/${vm.$route.params.cmpyCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              response.data.resultData.corporateFile.forEach(fileItem=>{
                fileItem.src = ApiConfig.efsDomain + "/efs/file/"+ fileItem.fileId + "/" + fileItem.fileSeqNo
                fileItem.name = fileItem.orgFileNm
                fileItem.size = fileItem.fileSize
              })
              vm.cmpyInfo = response.data.resultData
              if(response.data.resultData.companyMngResponseList?.length > 0){
                vm.cmpyMngList = EtnersCommonUtil.setRowNumber(response.data.resultData.companyMngResponseList)
              }
              vm.isPassCmpyNm = false
              vm.isCheckCmpyNm = true
              vm.bfCmpyNm = vm.cmpyInfo.cmpyNm
              vm.initCmpyNm = vm.cmpyInfo.cmpyNm
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    async onClickCompanyInfoSave () {
      const vm = this
      if (vm.validator()) {
        await kendo.confirm('저장 하시겠습니까?').done(
          async function () {
            await vm.setCompanyManageSave()
          })
      }
    },
    async setCompanyManageSave() {
      const vm = this
      try {
        vm.$store.dispatch('LOADING', true)
        let params = { loginUserToken: vm.$store.state.loginStore.lutk }
        if(vm.cmpyInfo.corporateFile?.length > 0){
          let fileSaveReslut = await vm.$refs.corporateFileIdRef.saveFile(params)
          vm.cmpyInfo.corporateFileId = EtnersCommonUtil.isNotEmpty(fileSaveReslut.fileId) ? fileSaveReslut.fileId : vm.cmpyInfo.corporateFileId
        }else {
          vm.cmpyInfo.corporateFileId = null
        }
        delete vm.cmpyInfo.companyMngResponseList
        vm.cmpyInfo.companyUpdateMngRequestParamList =
            vm.cmpyMngList.reduce((newData, item) => {
              let newItem = {
                cmpyCd : item.cmpyCd,
                unqUserId : item.unqUserId,
                locaCd : item.locaCd
              }
              newData.push(newItem)
              return newData
            }, [])

        if(vm.isNew){
          ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/company`, vm.cmpyInfo).then(response => {
            vm.$store.dispatch('LOADING', false)
            if (!response.data?.resultStatus) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }
            vm.$router.push({
              name: 'CompanyManageDetail',
              params: { cmpyCd : response.data.resultData.cmpyCd }
            })
          })
        }else {
          ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/company/${vm.$route.params.cmpyCd}`, vm.cmpyInfo).then(response => {
            vm.$store.dispatch('LOADING', false)
            if (!response.data?.resultStatus) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }
            vm.$router.push({
              name: 'CompanyManageDetail',
              params: { detailCd : vm.$route.params.cmpyCd }
            })
          })
        }
      }catch (e) {
        vm.$store.dispatch('LOADING', false)
      }
    },
    validator(){
      const vm = this
      if(!vm.cmpyInfo.cmpyGbn){
        kendo.alert("회사구분 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyGbnRef.kendoWidget().toggle()
        })
        return
      }
      if(!vm.isCheckCmpyNm && !(vm.cmpyInfo.cmpyNm === vm.initCmpyNm || vm.cmpyInfo.cmpyNm === vm.bfCmpyNm)){
        kendo.alert("회사명 중복체크를 진행해주세요.").bind('close', function() {
          vm.$refs.cmpyNmRef.focus()
        })
        return
      }
      if(!vm.cmpyInfo.corporateRegNo){
        kendo.alert("사업자등록번호를 입력해주세요.").bind('close', function() {
          vm.$refs.corporateRegNoRef.focus()
        })
        return
      }else if(!!vm.cmpyInfo.corporateRegNo && EtnersCommonUtil.extractOnlyNumbers(vm.cmpyInfo.corporateRegNo).length !== 10){
        kendo.alert("사업자등록번호 10자리를 입력해주세요.").bind('close', function() {
          vm.$refs.corporateRegNoRef.focus()
        })
        return
      }
      if(!vm.cmpyInfo.cmpyNm){
        kendo.alert("회사명을 입력해주세요.").bind('close', function() {
          vm.$refs.cmpyNmRef.focus()
        })
        return
      }

      if(!vm.cmpyInfo.zipNo || !vm.cmpyInfo.addr ){
        kendo.alert("주소를 검색해주세요.").bind('close', function() {
          vm.$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()
        })
        return
      }

      if(!vm.cmpyInfo.addrDtl){
        kendo.alert("상세주소를 입력해주세요.").bind('close', function() {
          vm.$refs.addrDtlRef.focus()
        })
        return
      }

      if(vm.cmpyInfo.cmpyGbn === vm.constant.cmpyGbn.client && vm.cmpyInfo.cmpyUnqText?.length !==4 ){
        kendo.alert("식별문자 4자리를 입력해주세요.").bind('close', function() {
          vm.$refs.cmpyUnqTextRef.focus()
        })
        return
      }

      if(vm.isNew && (!vm.cmpyInfo.locaZipNo || !vm.cmpyInfo.locaAddr)){
        kendo.alert("로케이션 주소를 선택해주세요.").bind('close', function() {
          vm.$refs.defaultLocaInfoRef.kendoWidget().toggle()
        })
        return
      }

      if(vm.cmpyMngList.length < 1){
        kendo.alert("관리자를 선택해주세요.").bind('close', function() {
          vm.$refs.setSearchWindowRef.kendoWidget().center().open()
        })
        return
      }

      return true
    },
  },
  data      : function () {
    const vm = this
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      ApiConfig : { efsDomain : ApiConfig.efsDomain },
      isWindowOpen : false,
      isNew : true,
      isCheckCmpyNm : false,
      isPassCmpyNm : false,
      bfCmpyNm : null,
      initCmpyNm : null,
      constant : {
        cmpyGbn : {
          client : '02'
        }
      },
      rowItem : {
        data : {
          rowNumber : '',
          setCd : '',
          setGbn : '',
        },
        index : '',
      },
      cmpyInfo  : {
        cmpyUnqText : '',
        zipNo : '',
        addr : '',
        addrDtl : ''
      },
      cmpyMngList : [],
      gridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'userNm',
          title: '성명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'userId',
          title: 'ID',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'spTelNo',
          title: '휴대전화번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'deleteBtn',
          title: '삭제',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          command: [{
            name: "delete",
            template : `<a class="mid_btn k-grid-delete">삭제</a>`,
            click: function(e) {
              e.preventDefault();

              let tr = kendo.jQuery(e.target).closest("tr");
              let data = this.dataItem(tr);

              vm.cmpyMngList.every((item , i)=>{
                if(item.userId === data.userId){
                  vm.cmpyMngList.splice(i,1)
                  return false
                }else return true
              })
            }
          }]
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
